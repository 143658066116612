import React from 'react';
import { paths } from '@src/constants/pathsEnum';

import { NewStandardSection } from '@commons/index';
import { Button } from '@commons/button/Button';

import partnerImage from './images/partner.png';

import * as styles from './strategic-partner.module.scss';

export const StrategicPartner = ({ sectionProps, description, buttonLabel, alt }) => {
  return (
    <NewStandardSection
      {...sectionProps}
      classNames={{
        section: styles.section,
        innerWrapper: styles.innerWrapper,
        titlesContainer: styles.titlesContainer,
      }}
    >
      <div className={styles.buttonTextContainer}>
        <p>{description}</p>
        <Button label={buttonLabel} href={paths.ABOUT} className={styles.button} />
      </div>
      <div className={styles.imageContainer}>
        <img loading="lazy" src={partnerImage} alt={alt} />
      </div>
    </NewStandardSection>
  );
};
