import React from 'react';

import Layout from '@src/layouts';
import SEO from '@commons/SEO';

import { OurOffices } from '@commons/our-offices/OurOffices';
import { NewClientsTestimonials } from '@commons/index';
import {
  CareerBanner,
  StrategicPartner,
  ContactFormWithCheckedTiles,
  HeaderWithContactForm,
} from '@pages-impl/contact';

import {
  SEOProps,
  headerProps,
  ourOfficesProps,
  strategicPartnerProps,
  testimonialsProps,
  careerBannerProps,
} from '@pages-impl/contact/payload';

export default function Contact(props) {
  return (
    <Layout {...props} contactFormType={false}>
      <SEO {...SEOProps} />
      <HeaderWithContactForm {...headerProps} />
      <ContactFormWithCheckedTiles />
      <OurOffices {...ourOfficesProps} />
      <StrategicPartner {...strategicPartnerProps} />
      <NewClientsTestimonials {...testimonialsProps} />
      <CareerBanner {...careerBannerProps} />
    </Layout>
  );
}
