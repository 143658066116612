import React from 'react';
import { paths } from '@src/constants/pathsEnum';

import { NewStandardSection } from '@commons/index';
import { Button } from '@commons/button/Button';

import * as styles from './career-banner.module.scss';

export const CareerBanner = ({ title, subtitle, buttonLabel }) => {
  return (
    <NewStandardSection classNames={{ innerWrapper: styles.innerWrapper, section: styles.section }}>
      <div className={styles.textContainer}>
        <h2 className={styles.title}>{title}</h2>
        <h3 className={styles.subtitle}>{subtitle}</h3>
      </div>
      <Button label={buttonLabel} href={paths.CAREERS} className={styles.button} type="dark" />
    </NewStandardSection>
  );
};
