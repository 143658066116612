import featuredImage from './images/featured-image.jpg';

import * as styles from './payload.module.scss';

export const SEOProps = {
  title: 'Contact us | CodiLime',
  description: 'Network expertise. Professionally delivered.',
  featuredImage: `https://codilime.com${featuredImage}`,
};

export const headerProps = {
  title: 'Contact us',
};

export const ourOfficesProps = {
  mainTitle: 'Our locations',
  classNames: {
    innerWrapper: styles.ourOfficesInnerWrapper,
  },
};

export const strategicPartnerProps = {
  sectionProps: {
    title: 'A strategic partner for technology-driven companies',
    id: 'strategic-partner',
  },
  description:
    'Need support in building or deploying your solution? CodiLime has been helping clients worldwide boost their technology-driven businesses since 2011. With top-notch engineers and thinkers on board, we work efficiently and build custom solutions to help you astonish your customers.',
  alt: 'Get in touch with a strategic partner for technology-driven companies',
  buttonLabel: 'Explore CodiLime',
};

export const testimonialsProps = {
  sectionProps: {
    id: 'trusted-by-leaders',
  },
  leftTitle: 'What our Clients say about us',
};

export const careerBannerProps = {
  title: 'Interested in a career at CodiLime?',
  subtitle: 'Check our open positions and apply now!',
  buttonLabel: 'Go to job board',
};
